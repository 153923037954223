
import { defineComponent } from 'vue';
import { CardMedSession, Snackbar } from '@/components/';
import { UserController } from '@/controller';
import store from '@/store/index';

export default defineComponent({
    name: "Favorits",      
    components: { CardMedSession, Snackbar },
    data(){
        return{
            store,
            user: {} as any,
            categoryFilter: -1,
            snackbar: {
                show: false,
                text: '',
                error: false
            },  
            icons:{
                logo: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
                herz_outline: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 300" enable-background="new 0 0 300 300" xml:space="preserve"><g id="heart-svgrepo-com" transform="translate(0 -28.501)"><path fill="none" stroke="#183E64" stroke-width="45" stroke-miterlimit="10" d="M252.2,91.1c-26.4-26.3-69.2-26.3-95.5,0l-6.7,6.7l-6.7-6.7C117,64.7,74.3,64.6,48,90.9c0,0,0,0-0.1,0.1c-26.3,26.3-26.3,69.1,0,95.4c0,0,0,0,0.1,0.1l97.2,97.2c2.7,2.7,7,2.7,9.8,0.1l0,0l97.4-97C278.4,160.3,278.4,117.6,252.2,91.1L252.2,91.1z"/></g></svg>',
            },
        }
    },
    created(){
        (document.getElementById('app') as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        this.fetchData();
    },
    methods:{
        async fetchData(){
            this.user = await UserController.user
        },
        handleOpenDetailView(sessionId : any){
            this.$router.push('/medsession/'+ sessionId)
            //this.handleGetMedSession();
        }, 
        handleManageAbo(){
            this.$router.push('/manageabo')
        },
        handleShowSnackbar(text:string, error : boolean){
            this.snackbar.show = true
            this.snackbar.text = text
            this.snackbar.error = error

            setTimeout(() => {
                this.snackbar.show = false
            }, 5000)
        }
        /*async getMedSessions(){
            const res : any = await MedSessionsController.getMedSessions();
            if(res.data.success){
                this.medSessions = MedSessionsController.medSessions;
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
        },*/
        
        /*async handleGetMedSession(){
            let res : any = await MedSessionsController.getMedSession(this.currentMedSession.uid)
            if(res.data.success){
                this.$router.push('/medsession/'+ res.data.data.uid)
            } else {
                if(res.data.errors[0].field == 'abo' && !this.dontShowAgain){
                    this.handleShowNoFreeSessions = true
                } else{
                    this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
                }
            }
        },
        getDisabledCard(statistic:any){           
            if(this.user.freeSessionsLeft >0){
                return false
            } else if (this.user.freeSessionsLeft <= 0 && statistic && statistic.available==1){
                return false
            } else {
                return true
            }
        },*/
    }
})
