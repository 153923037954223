import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "favorits-container" }
const _hoisted_2 = { class: "main-content" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "cards-container cards-col-3"
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardMedSession = _resolveComponent("CardMedSession")!
  const _component_Snackbar = _resolveComponent("Snackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, [
        _createTextVNode(_toDisplayString(_ctx.$t('heading_favorites')) + " ", 1),
        _createElementVNode("i", {
          innerHTML: _ctx.icons.herz_outline,
          class: "heading-icon"
        }, null, 8, _hoisted_3)
      ]),
      (_ctx.user.favorits && _ctx.user.favorits.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.favorits, (item) => {
              return (_openBlock(), _createBlock(_component_CardMedSession, {
                key: item.uid,
                medSession: item,
                allSessionsAvailable: _ctx.user.abo.allSessionsAvailable == 1 ? true : false,
                onClick: (event) => _ctx.handleOpenDetailView(item.uid)
              }, null, 8, ["medSession", "allSessionsAvailable", "onClick"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('favorites_empty')), 1)
          ]))
    ]),
    _createVNode(_component_Snackbar, {
      show: _ctx.snackbar.show,
      text: _ctx.snackbar.text,
      error: _ctx.snackbar.error,
      aboveBottomMenu: true
    }, null, 8, ["show", "text", "error"])
  ]))
}